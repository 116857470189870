<template>
  <VModalWidget :dismiss="dismiss" :submit="submit" :submit-title="$t('labels.create')">
    <template v-slot:title>{{ $t('labels.file') }}</template>
    <template v-slot:content>
      <v-file-input :label="$t('labels.selectFile')" :prepend-icon="null" v-model="file" :error-messages="fileErrors">
        <template v-slot:append>
          <v-icon small>{{ $vuetify.icons.values.attachment }}</v-icon>
        </template>
      </v-file-input>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { VModalWidget, BaseForm } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'ModalLeadFile',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    VModalWidget
  },
  data: () => ({
    file: null,
    loading: false
  }),
  computed: {
    fileErrors() {
      const errors = []
      this.getServerErrors('file', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['addFile']),
    submit() {
      this.loading = true
      let data = new FormData()
      data.append('file', this.file)
      this.addFile(data)
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
    }
  }
}
</script>
